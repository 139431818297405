import { PropsWithChildren, useRef } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';

import { styled } from '@styles/stitches.config';

const Section = ({ children }: PropsWithChildren<{}>) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {
    threshold: 0.1,
    freezeOnceVisible: true,
  });
  const isVisible = !!entry?.isIntersecting;

  return (
    <SectionWrapper ref={ref} show={isVisible}>
      {children}
    </SectionWrapper>
  );
};

const SectionWrapper = styled('div', {
  paddingX: 16,

  opacity: 0,
  transform: 'translate(0, 100px)',
  transition: 'opacity 300ms ease-in, transform 300ms ease-in',

  '@landingMobile': {
    transform: 'translate(0, 50px)',
  },

  variants: {
    show: {
      true: {
        transform: 'translate(0)',
        opacity: 1,
      },
    },
  },
});

export default Section;
