import Image from 'next/future/image';
import { useRouter } from 'next/router';

import fullLogo from '../assets/full-logo.svg';
import logo from '../assets/logo.svg';

import Button from '@design0.2/Button/Button';
import useSignInAndUpModal from '@shared/hooks/useSignInAndUpModal';
import { styled } from '@styles/stitches.config';

const Header = () => {
  const router = useRouter();

  const signInAndUpModal = useSignInAndUpModal();

  const handleClickSignIn = () => {
    signInAndUpModal.open({
      purpose: 'normal',
      type: 'signIn',
      redirectTo: router.asPath,
    });
  };

  const handleClickSignUp = () => {
    signInAndUpModal.open({
      purpose: 'normal',
      type: 'signUp',
      redirectTo: router.asPath,
    });
  };

  return (
    <Container>
      <LogoContainer>
        <Logo src={logo} alt={'Kemi'} />
        <FullLogo src={fullLogo} width={80} height={19} alt={'Kemi'} />
      </LogoContainer>
      <SignAndUp>
        <Button buttonStyle={'tertiary'} onClick={handleClickSignIn}>
          {'로그인'}
        </Button>
        <SignUpButton onClick={handleClickSignUp}>{'회원가입'}</SignUpButton>
      </SignAndUp>
    </Container>
  );
};

const Container = styled('div', {
  position: 'sticky',
  left: 0,
  width: '100%',
  background: 'rgba(255, 255, 255, 0.72)',
  backdropFlter: 'blur(32px)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px 28px 20px 33px',
  zIndex: '$contentHeader',
  paddingTop: 'calc(20px + 16px)',
  top: '-16px',

  '@landingTablet': {
    padding: 16,
    paddingTop: 'calc(16px + 16px)',
  },
});

const LogoContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

const Logo = styled(Image, {
  '@landingTablet': {
    display: 'none',
  },
});

const FullLogo = styled(Image, {
  display: 'none',

  '@landingTablet': {
    display: 'initial',
  },
});

const SignAndUp = styled('div', {
  display: 'flex',
  gap: 8,
});

const SignUpButton = styled(Button, {
  whiteSpace: 'nowrap',
});

export default Header;
