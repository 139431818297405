import { ReactNode } from 'react';

import { styled } from '@styles/stitches.config';

type TitleAndDescriptionProps = {
  title: string | ReactNode;
  description: string | ReactNode;
};

const TitleAndDescription = ({
  title,
  description,
}: TitleAndDescriptionProps) => {
  return (
    <Container>
      <div>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
      </div>
    </Container>
  );
};

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

const Title = styled('h2', {
  fontType: 'heading3',
  whiteSpace: 'pre-line',
});

const Description = styled('p', {
  marginTop: 24,
  fontType: 'subtitleRegular',
  whiteSpace: 'pre-line',
});

export default TitleAndDescription;
