import React from 'react';
import { UAParser } from 'ua-parser-js';

import Landing from '../landing/pages/Landing';

import ROUTE from '@routers';
import { withDefaultGetServerSideProps } from '@shared/helpers/next';
import { KemiNextPage } from '@shared/types/next';
import { ACCESS_TOKEN_KEY } from 'src/auth/modules/kemiToken';

const HomePage: KemiNextPage = () => {
  return <Landing />;
};

// eslint-disable-next-line @wired/prefer-client-side-rendering
export const getServerSideProps = withDefaultGetServerSideProps(async function (
  context
) {
  const uaParserResult = UAParser(context.req.headers['user-agent']);

  if (uaParserResult.browser.name === 'IE') {
    return {
      redirect: {
        destination: '/ie.html',
        permanent: false,
      },
    };
  }

  const accessToken = context.req.cookies[ACCESS_TOKEN_KEY];

  if (!accessToken) {
    return { props: {} };
  }

  return {
    redirect: {
      destination: ROUTE.mykemi.index,
      permanent: false,
    },
  };
});

HomePage.layoutType = 'NONE';

export default HomePage;
