import type * as Stitches from '@stitches/react';
import { ReactNode } from 'react';

import { ColorType, FontType, styled } from '@styles/stitches.config';

type TextProps = {
  type?: FontType;
  color?: ColorType;
  children: ReactNode;
  css?: Stitches.CSS;
  onClick?: () => void;
};

const Text = ({
  type = 'bodyRegular',
  color = 'inherit',
  children,
  css,
  onClick,
}: TextProps) => (
  <Span css={{ fontType: type, color, ...css }} onClick={onClick}>
    {children}
  </Span>
);

const Span = styled('span');

export default Text;
