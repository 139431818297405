import { StaticImageData } from 'next/image';
import { ReactNode, useEffect, useState } from 'react';

import Image from '@global/components/atoms/Image/Image';
import { styled } from '@styles/stitches.config';
import LinkNameInput from 'src/landing/components/LinkNameInput';

type MainImageAndLinkNameInputProps = {
  imageSrc: StaticImageData;
  title: string | ReactNode;
  description?: string | ReactNode;
  bgColor: 'mint100' | 'yellow100';
};

const MainImageAndLinkNameInput = ({
  imageSrc,
  title,
  description,
  bgColor,
}: MainImageAndLinkNameInputProps) => {
  const [linkName, setLinkName] = useState('');

  useEffect(() => {}, []);
  return (
    <Container bgColor={bgColor}>
      <TitleAndDescription>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
      </TitleAndDescription>
      <ImageBox>
        <Image {...imageSrc} alt={'landing-page-main-image'} />
      </ImageBox>
      <InputBox>
        <MakeKemiBubble>
          <Bubble>
            <span>{'👇 3초만에 무료로 만들기!'}</span>
          </Bubble>
          <Arrow bgColor={bgColor} />
        </MakeKemiBubble>
        <LinkNameInput
          linkName={linkName}
          onChange={setLinkName}
          validTextColor={bgColor === 'yellow100' ? 'grey100' : 'grey20'}
        />
      </InputBox>
    </Container>
  );
};

const Container = styled('div', {
  display: 'grid',
  gap: 64,
  borderRadius: 40,
  marginTop: 16,

  '@landingTablet': {
    gridTemplateColumns: '1fr 0.8fr',
    alignItems: 'center',

    '& > div:nth-child(1)': {
      gridColumn: '1 / 2',
      gridRow: '1 / 2',
    },

    '& > div:nth-child(2)': {
      gridColumn: '2 / 3',
      gridRow: '1 / 3',
    },

    '& > div:nth-child(3)': {
      gridColumn: '1 / 2',
      gridRow: '2 / 3',
    },
  },

  variants: {
    bgColor: {
      mint100: {
        backgroundColor: '$mint100',
      },
      yellow100: {
        backgroundColor: '$yellow100',
      },
    },
  },
});

const TitleAndDescription = styled('div', {
  padding: '100px 24px 0 24px',

  '@landingTablet': {
    padding: '64px 32px 0 32px',
  },
  '@landingPC': {
    padding: '64px 120px 0 120px',
  },
});

const Title = styled('h1', {
  fontType: 'heading2',
  color: '$black',
  whiteSpace: 'pre-line',
  letterSpacing: '-0.5px',
});

const Description = styled('div', {
  marginTop: 24,
  fontType: 'subtitleRegular',
  whiteSpace: 'pre-line',
});

const InputBox = styled('div', {
  padding: '0 24px 100px 24px',

  '@landingTablet': {
    padding: '0 32px 64px 32px',
  },

  '@landingPC': {
    padding: '0 120px 64px 120px',
  },
});

const ImageBox = styled('div', {
  width: '100%',
});

const MakeKemiBubble = styled('div', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 24,
});

const Bubble = styled('div', {
  width: 157,
  height: 34,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '$white',
  borderRadius: 8,
  fontType: 'captionRegular',
});

const Arrow = styled('div', {
  position: 'absolute',
  backgroundColor: '$white',
  top: 34,
  borderTop: '8px solid $white',

  variants: {
    bgColor: {
      mint100: {
        borderLeft: '8px solid $mint100',
        borderRight: '8px solid $mint100',
      },
      yellow100: {
        borderLeft: '8px solid $yellow100',
        borderRight: '8px solid $yellow100',
      },
    },
  },
});

export default MainImageAndLinkNameInput;
