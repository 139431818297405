import image1 from '../assets/09-1-statistics-feature-01.png';
import image2 from '../assets/09-2-statistics-feature-02.png';
import image3 from '../assets/09-3-statistics-feature-03.png';

import TitleAndDescription from './TitleAndDescription';

import Image from '@global/components/atoms/Image';
import { styled } from '@styles/stitches.config';

const SellerStatistics = () => {
  return (
    <Container>
      <TitleAndDescription
        title={'1인 마켓 판매자에게\n진짜로 필요한 정보만 모았어요'}
        description={
          <>
            <Span>
              {
                '어디에나 있는 통계 속 정보를 유의미하게\n활용한 적이 많으신가요?'
              }
            </Span>
            <Span>{'\n\n'}</Span>
            <BoldSpan>
              {'통계에서 가장 중요한 건 내 고객 분석이에요 🧐'}
            </BoldSpan>
          </>
        }
      />
      <FeatureCards>
        <FeatureCard>
          <ImageBox>
            <ImageWidthBox>
              <Image {...image1} alt={'kemi-statistics-image'} />
            </ImageWidthBox>
          </ImageBox>
          <Description>
            {
              '어떤 콘텐츠를 보고 케미에 방문했고,\n구매까지 이어졌는지 알아보세요!'
            }
          </Description>
        </FeatureCard>
        <FeatureCard>
          <ImageBox>
            <ImageWidthBox>
              <Image {...image2} alt={'kemi-statistics-image'} />
            </ImageWidthBox>
          </ImageBox>
          <Description>
            {
              '재구매율을 높이는 다양한 통계 정보로\n단골고객을 만들고 성장하세요!'
            }
          </Description>
        </FeatureCard>
        <FeatureCard>
          <ImageBox>
            <ImageWidthBox>
              <Image {...image3} alt={'kemi-statistics-image'} />
            </ImageWidthBox>
          </ImageBox>
          <Description>
            {
              '내 팔로워의 관심사를 확인하고\n상품과 콘텐츠를 준비해 매출을 올려보세요!'
            }
          </Description>
          <Remark>{`*${'도입예정인 기능입니다.'}`}</Remark>
        </FeatureCard>
      </FeatureCards>
    </Container>
  );
};

const Container = styled('div', {
  marginTop: 240,
});

const FeatureCards = styled('div', {
  display: 'grid',
  gap: 16,
  justifyContent: 'center',
  marginTop: 120,

  '@landingTablet': {
    gridTemplateColumns: 'repeat(2, 1fr)',

    '& > div:nth-child(1)': {
      gridColumn: '1 / 2',
      gridRow: '1 / 3',
    },

    '& > div:nth-child(2)': {
      gridColumn: '2 / 3',
      gridRow: '1 / 2',
    },

    '& > div:nth-child(3)': {
      gridColumn: '2 / 3',
      gridRow: '2 / 3',
    },
  },

  '@landingPC': {
    gridTemplateColumns: 'repeat(3, 1fr)',

    '& > div:nth-child(1)': {
      gridColumn: '1 / 2',
      gridRow: '1 / 2',
    },

    '& > div:nth-child(2)': {
      gridColumn: '2 / 3',
      gridRow: '1 / 2',
    },

    '& > div:nth-child(3)': {
      gridColumn: '3 / 4',
      gridRow: '1 / 2',
    },
  },
});

const FeatureCard = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 8,
  padding: 24,
  paddingBottom: 64,
  backgroundColor: '$blue50',
  borderRadius: 40,

  '@landingPC': {
    justifyContent: 'flex-start',
  },
});

const ImageBox = styled('div', {
  marginBottom: 24,

  '@landingPC': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 394,
  },
});

const ImageWidthBox = styled('div', {
  '@landingPC': {
    maxWidth: 320,
  },
});

const Description = styled('p', {
  fontType: 'subtitleRegular',
  whiteSpace: 'pre-line',
});

const BoldSpan = styled('span', {
  display: 'block',
  fontType: 'subtitleBold',
});

const Span = styled('span', {
  whiteSpace: 'pre-line',
});

const Remark = styled('span', {
  marginTop: 8,
  fontType: 'captionRegular',
  color: '$grey100',
});

export default SellerStatistics;
