import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import { StaticImageData } from 'next/image';
import { ReactNode, useMemo } from 'react';

import Image from '@global/components/atoms/Image';
import { styled } from '@styles/stitches.config';

const emblaOptions = {
  loop: true,
  draggable: true,
  dragFree: true,
};

type KemiPreviewsProps = {
  title: ReactNode;
  imageSrcs: StaticImageData[];
};
const KemiPreviews = ({ title, imageSrcs }: KemiPreviewsProps) => {
  const imageByIndex = (index: number) => imageSrcs[index % imageSrcs.length];
  const autoSlidePlugin = useMemo(() => {
    return Autoplay({ delay: 2500, stopOnInteraction: false });
  }, []);

  const [emblaRef] = useEmblaCarousel(emblaOptions, [autoSlidePlugin]);

  return (
    <Container>
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
      <Embla>
        <EmblaViewPort ref={emblaRef}>
          <EmblaContainer>
            {imageSrcs.map((_, i) => {
              return (
                <Slide key={i}>
                  <SlideInner>
                    <Image {...imageByIndex(i)} alt={'sliding-image'} />
                  </SlideInner>
                </Slide>
              );
            })}
          </EmblaContainer>
        </EmblaViewPort>
      </Embla>
    </Container>
  );
};

const Container = styled('div', {
  marginTop: 120,
});

const TitleContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
});

const Title = styled('div', {
  marginBottom: 120,
});

const Embla = styled('div', {
  position: 'relative',
});

const EmblaViewPort = styled('div', {
  overflow: 'hidden',
  width: '100%',
});

const EmblaContainer = styled('div', {
  display: 'flex',
  userSelect: 'none',
  '-webkit-touch-callout': 'none',
  '-khtml-user-select': 'none',
  '-webkit-tap-highlight-color': 'transparent',
  marginLeft: '-10px',
});

const Slide = styled('div', {
  position: 'relative',
  paddingLeft: 10,
});

const SlideInner = styled('div', {
  position: 'relative',
  overflow: 'hidden',
  width: 160,
  height: 352,
});

export default KemiPreviews;
