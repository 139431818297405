import preview1 from '../assets/02-1-seller-kemi-preview-01.png';
import preview2 from '../assets/02-2-seller-kemi-preview-02.png';
import preview4 from '../assets/02-4-seller-kemi-preview-04.png';
import preview5 from '../assets/02-5-seller-kemi-preview-05.png';

import KemiPreviews from './KemiPreviews';

import { styled } from '@styles/stitches.config';

const imageSrcs = [
  preview1,
  preview2,
  preview4,
  preview5,
  preview1,
  preview2,
  preview4,
  preview5,
];

const KemiSellerPreviews = () => {
  return (
    <KemiPreviews
      title={
        <Title>
          {'내 마켓을 가장 잘 표현할 수 있는\n홈페이지를 만들어보세요!'}
        </Title>
      }
      imageSrcs={imageSrcs}
    />
  );
};

const Title = styled('h2', {
  textAlign: 'left',
  whiteSpace: 'pre-line',
  fontType: 'heading3',
  color: '$black',
});

export default KemiSellerPreviews;
