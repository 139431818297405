import sellerImage from '../assets/05-1-statistics-seller.gif';
import profitImage from '../assets/05-2-statistics-profit.gif';
import userImage from '../assets/05-3-statistics-user.gif';

import TitleAndDescription from './TitleAndDescription';

import CardList from 'src/landing/components/CardList';

const KemiData = () => {
  const kemiDataCard = [
    {
      imageSrc: sellerImage,
      description: '함께하는 판매자',
      title: '66,000명+',
    },
    {
      imageSrc: profitImage,
      description: '누적 매출',
      title: '7,700,000,000원+',
    },
    {
      imageSrc: userImage,
      description: '누적 방문자수',
      title: '3,800,000명+',
    },
  ];

  return (
    <>
      <TitleAndDescription
        title={'이미 많은 분이\n케미와 함께\n영향력을 수익화하고 있어요'}
        description={null}
      />
      <CardList
        gridType={'twoRowsInFirstColumn'}
        bgColor={'purple'}
        contentOrder={'imageDescriptionTitle'}
        contentGap={8}
        cardAlign={'center'}
        contentPadding={64}
        cards={kemiDataCard}
      />
    </>
  );
};
export default KemiData;
