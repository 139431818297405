import { useRouter } from 'next/router';

import usp01 from '../assets/03-1-seller-usp-01.png';
import usp02 from '../assets/03-2-seller-usp-02.png';
import usp03 from '../assets/03-3-seller-usp-03.png';
import usp04 from '../assets/03-4-seller-usp-04.png';
import usp05 from '../assets/03-5-seller-usp-05.png';
import usp06 from '../assets/03-6-seller-usp-06.png';

import IntroductionCard from './IntroductionCard';
import LandingButton from './LandingButton';

import useSignInAndUpModal from '@shared/hooks/useSignInAndUpModal';
import { styled } from '@styles/stitches.config';

const SellerServiceIntroduction = () => {
  const router = useRouter();

  const signInAndUpModal = useSignInAndUpModal();

  const handleClickSignUp = () => {
    signInAndUpModal.open({
      purpose: 'normal',
      type: 'signUp',
      redirectTo: router.asPath,
    });
  };

  const introductionData = [
    {
      title: '나만의 온라인 쇼핑몰 생성',
      imageSrc: usp01,
      description:
        '현재 내가 판매 중인 상품이나 케미 추천 상품을\n등록해 자유롭게 판매할 수 있어요',
      wide: true,
    },
    {
      title: '1인 마켓에 최적화된 통계',
      imageSrc: usp02,
      description:
        '정말 필요한 정보만 담은 통계를 활용하여\n내 고객을 분석하고 마켓을 성장시킬 수 있어요',
      wide: true,
    },
    {
      title: '오로지 판매에만 집중 할 수 있는 환경',
      imageSrc: usp03,
      description:
        '배송 상태를 자동으로 고객에게 알려주고,\n전문 매니저가 고객 관리를 대신해요',
      wide: true,
    },
    {
      title: '전담 매니저와 함께하는마켓 성장',
      imageSrc: usp04,
      description:
        '판매 데이터가 쌓이면 전담 매니저가 배정되어\n판매자님의 더 큰 성장을 도와드려요',
      wide: true,
    },
    {
      title: '상품이 하나여도 충분한 쇼핑몰',
      imageSrc: usp05,
      description:
        '상품 1개로 그럴싸한 쇼핑몰을 꾸미는 건 어렵지만 케미는 1개만으로도 완벽한 쇼핑몰이 될 수 있어요',
      wide: true,
    },
    {
      title: '내 개성을 자유롭게 표현하는 공간',
      imageSrc: usp06,
      description:
        '링크, SNS연동, 텍스트, 사진, 영상, 음악에 내 개성을 담아 나만의 홈페이지를 만들 수 있어요',
      wide: true,
    },
  ];

  return (
    <>
      <IntroductionCards>
        {introductionData.map((data, i) => (
          <IntroductionCard key={i} {...data} />
        ))}
      </IntroductionCards>
      <Container>
        <LandingButton
          text={'지금 무료로 시작하기'}
          theme={'black'}
          onClick={handleClickSignUp}
        />
      </Container>
    </>
  );
};

const IntroductionCards = styled('div', {
  display: 'grid',
  justifyContent: 'center',
  width: '100%',
  marginY: 120,

  '@landingPC': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
});

const Container = styled('div', {
  margin: '0 auto',
  width: '100%',
});

export default SellerServiceIntroduction;
