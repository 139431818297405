/* eslint-disable quotes */
import { useRouter } from 'next/router';

import LandingButton from './LandingButton';

import Input from '@global/components/atoms/Input';
import { MAX_INPUT_LENGTH } from '@global/constants';
import { linkNameValidator } from '@global/service/validator';
import useSignInAndUpModal from '@shared/hooks/useSignInAndUpModal';
import { styled } from '@styles/stitches.config';

type LinkNameInputProps = {
  linkName: string;
  onChange: (linkName: string) => void;
  validTextColor?: 'grey100' | 'grey20';
};

const LinkNameInput = ({
  linkName,
  onChange,
  validTextColor,
}: LinkNameInputProps) => {
  const router = useRouter();

  const signInAndUpModal = useSignInAndUpModal();

  const handleClickStartKemi = () => {
    signInAndUpModal.open({
      purpose: 'normal',
      type: 'signUp',
      redirectTo: router.asPath,
      linkName,
    });
  };

  return (
    <>
      <LandingInput
        value={linkName}
        fixedPlaceholder={'kemi.io/'}
        fixedPlaceholderColor={'black'}
        placeholder={'name'}
        onChange={onChange}
        maxLength={MAX_INPUT_LENGTH.LINKNAME}
        errorBorder={
          linkName.length >= 1 ? !!linkNameValidator(linkName) : false
        }
      />
      <ValidTextNotice color={validTextColor}>
        <span>
          {'최소 2자 이상. 영어 소문자, 숫자, ‘_’ 만 사용 가능합니다.'}
        </span>
      </ValidTextNotice>
      <div>
        <LandingButton
          text={'케미 시작하기'}
          theme={'black'}
          onClick={handleClickStartKemi}
        />
      </div>
    </>
  );
};

const LandingInput = styled(Input, {
  padding: '24px 32px !important',
  border: '1px solid #303030 !important',
  borderRadius: '32px !important',
  boxShadow: '8px 8px 0px #303030',
});

const ValidTextNotice = styled('div', {
  display: 'block',
  marginY: 16,
  width: '100%',
  textAlign: 'center',
  color: '$grey20',

  variants: {
    color: {
      grey20: {
        color: '$grey20',
      },
      grey100: {
        color: '$grey100',
      },
    },
  },

  '& > span': {
    fontType: 'labelRegular',
  },
});

export default LinkNameInput;
