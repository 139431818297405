import { useState } from 'react';

import { styled } from '@styles/stitches.config';
import LinkNameInput from 'src/landing/components/LinkNameInput';

const ReadyToStart = () => {
  const [linkName, setLinkName] = useState('');

  return (
    <Container>
      <Title>{'케미를 시작할 준비가\n되셨나요?'}</Title>
      <Descriptions>
        <Description>
          <Icon>{'🌟'}</Icon>
          <span>{'원하는 도메인을 선점하세요.'}</span>
        </Description>
        <Description>
          <Icon>{'💳'}</Icon>
          <span>{'일부 기능이 유료화되기 전 함께하세요.'}</span>
        </Description>
        <Description>
          <Icon>{'🚚'}</Icon>
          <span>
            {
              '링크트리, 인포크링크 등 사용 중인 프로필링크 주소를\n입력하면 3초 만에 케미로 옮겨드려요.'
            }
          </span>
        </Description>
      </Descriptions>
      <LinkNameInput
        linkName={linkName}
        onChange={setLinkName}
        validTextColor={'grey100'}
      />
    </Container>
  );
};

const Container = styled('div', {
  width: '100%',
  paddingX: 24,
  margin: '120px auto 0 auto',

  '@landingTablet': {
    width: '400px',
  },
});

const Title = styled('h2', {
  fontType: 'heading3',
  marginBottom: 24,
  whiteSpace: 'pre-line',
});

const Descriptions = styled('div', {
  display: 'grid',
  gap: 8,
  marginBottom: 24,
});

const Description = styled('p', {
  display: 'flex',
  fontType: 'bodyRegular',
  whiteSpace: 'pre-line',
});

const Icon = styled('span', {
  display: 'block',
  marginRight: 8,
});

export default ReadyToStart;
