import Footer from '@global/components/molecules/Footer/Footer';
import { styled } from '@styles/stitches.config';

const LandingFooter = () => {
  return (
    <Container>
      <Footer transparent />
    </Container>
  );
};

const Container = styled('div', {
  width: '100%',
  padding: 16,
  paddingBottom: 64,
  color: '$grey100',
});

export default LandingFooter;
