import mainImage from '../assets/01-seller-top.gif';
import Header from '../components/Header';
import KemiData from '../components/KemiData';
import KemiSellerPreviews from '../components/KemiSellerPreviews';
import LandingFooter from '../components/LandingFooter';
import MainImageAndLinkNameInput from '../components/MainImageAndLinkNameInput';
import ReadyToStart from '../components/ReadyToStart';
import Section from '../components/Section';
import SellerFeatures from '../components/SellerFeatures';
import SellerServiceIntroduction from '../components/SellerServiceIntroduction';
import SellerStatistics from '../components/SellerStatistics';

import { styled } from '@styles/stitches.config';
import DoYouHaveTroubleBubbles from 'src/landing/components/DoYouHaveTroubleBubbles';
import FAQ from 'src/landing/components/FAQ';

const Landing = () => {
  return (
    <Wrapper>
      <Content>
        <Header />
        <Section>
          <MainImageAndLinkNameInput
            imageSrc={mainImage}
            title={'온라인 마켓\n누구나 쉽고 빠르게\n판매가 시작되는 곳'}
            description={
              '온라인 판매 어떻게 해야 할지 고민하지 마세요.\n케미가 상품 판매에 필요한 모든 것을 지원합니다!'
            }
            bgColor={'yellow100'}
          />
        </Section>
        <Section>
          <DoYouHaveTroubleBubbles />
        </Section>
        <Section>
          <SellerServiceIntroduction />
        </Section>
        <Section>
          <KemiSellerPreviews />
        </Section>
        <Section>
          <SellerStatistics />
        </Section>
        <Section>
          <SellerFeatures />
        </Section>
        <Section>
          <KemiData />
        </Section>
        <Section>
          <ReadyToStart />
        </Section>
        <Section>
          <FAQ />
        </Section>
      </Content>
      <LandingFooter />
    </Wrapper>
  );
};

const Wrapper = styled('div', {
  fontFamily: 'Pretendard',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  backgroundColor: '$white',
});

const Content = styled('div', {
  width: '400px',
  maxWidth: '100%',
  backgroundColor: '$white',

  '@landingTablet': {
    width: '800px',
  },
  '@landingPC': {
    width: '1200px',
  },
});

export default Landing;
