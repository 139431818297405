import Icon from '@design0.2/Icon/Icon';
import { styled } from '@styles/stitches.config';

type LandingButtonProps = {
  text: string;
  theme: ThemeType;
  onClick?: () => void;
  linkTo?: string;
};

type ThemeType = 'black' | 'mint';

const LandingButton = ({
  text,
  theme,
  onClick,
  linkTo,
}: LandingButtonProps) => {
  if (linkTo) {
    return (
      <a target={'_blank'} href={linkTo} rel={'noreferrer'}>
        <LandingButtonContainer theme={theme} onClick={onClick}>
          <span>{text}</span>
          <ArrowRight>
            {theme === 'black' && <Icon type={'arrow-right | white'} />}
            {theme === 'mint' && <Icon type={'arrow-right | black'} />}
          </ArrowRight>
        </LandingButtonContainer>
      </a>
    );
  }

  return (
    <LandingButtonContainer theme={theme} onClick={onClick}>
      <span>{text}</span>
      <ArrowRight>
        {theme === 'black' && <Icon type={'arrow-right | white'} />}
        {theme === 'mint' && <Icon type={'arrow-right | black'} />}
      </ArrowRight>
    </LandingButtonContainer>
  );
};

const LandingButtonContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
  padding: 16,
  borderRadius: 28,
  cursor: 'pointer',

  variants: {
    theme: {
      black: {
        backgroundColor: '$black',
        color: '$white',
      },
      mint: {
        backgroundColor: '$mint100',
        color: '$black',
      },
    },
  },

  '& span': {
    fontType: 'bodyBold',
    fontFamily: 'Pretendard',
  },
});

const ArrowRight = styled('div', {
  position: 'absolute',
  right: 16,
});

export default LandingButton;
