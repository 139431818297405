import { StaticImageData } from 'next/image';

import Image from '@global/components/atoms/Image';
import { styled } from '@styles/stitches.config';

type IntroductionCardProps = {
  title: string;
  imageSrc: StaticImageData;
  description: string;
  wide?: boolean;
};

const IntroductionCard = ({
  title,
  imageSrc,
  description,
  wide,
}: IntroductionCardProps) => {
  return (
    <Wrapper>
      <Container>
        <Title>{title}</Title>
        <ImageBox wide={wide}>
          <Image {...imageSrc} alt={'introduction-card'} />
        </ImageBox>
        <Description>{description}</Description>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled('div', {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

const Container = styled('div', {
  width: '100%',
  maxWidth: 400,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 64,
  marginY: 64,
});

const Title = styled('h2', {
  fontType: 'heading3',
  width: '100%',
  textAlign: 'left',
  whiteSpace: 'pre-line',
  paddingX: 40,

  '@landingMobile': {
    paddingX: 30,
  },
});

const ImageBox = styled('div', {
  width: '100%',
  paddingX: 100,
  display: 'flex',
  justifyContent: 'center',

  variants: {
    wide: {
      true: {
        paddingX: 16,
      },
    },
  },
});

const Description = styled('p', {
  fontType: 'subtitleRegular',
  whiteSpace: 'pre-line',
  width: '100%',
  paddingX: 40,

  '@landingMobile': {
    paddingX: 30,
  },
});

export default IntroductionCard;
