import { StaticImageData } from 'next/image';

import Image from '@global/components/atoms/Image';
import { styled } from '@styles/stitches.config';

type Card = {
  imageSrc: StaticImageData;
  title?: string;
  description: string;
  remark?: string;
};

type ContentOrder =
  | 'imageTitleDescription'
  | 'imageDescriptionTitle'
  | 'imageDescription';

type CardListProps = {
  gridType: 'twoRowsInFirstColumn' | 'twoRowsInSecondColumn';
  contentOrder: ContentOrder;
  contentPadding?: 24 | 64;
  contentGap: 8 | 24;
  cardAlign?: 'center' | 'flexStart';
  bgColor: 'purple' | 'teal' | 'mint' | 'yellow' | 'blue';
  cards: Card[];
};

const CardList = ({
  gridType,
  contentOrder,
  contentPadding,
  cardAlign,
  contentGap,
  bgColor,
  cards,
}: CardListProps) => {
  const arrangeCards = (
    contentOrder: ContentOrder,
    card: Card,
    index: number
  ) => {
    switch (contentOrder) {
      case 'imageDescription':
        return (
          <CardBox
            key={index}
            contentGap={contentGap}
            bgColor={bgColor}
            contentPadding={contentPadding}
            cardAlign={cardAlign}
          >
            <Image {...card.imageSrc} alt={'landing-card-image'} />
            <Description>
              <span>{card.description}</span>
              {card.remark && <Remark>{card.remark}</Remark>}
            </Description>
          </CardBox>
        );
      case 'imageDescriptionTitle':
        return (
          <CardBox
            key={index}
            contentGap={contentGap}
            bgColor={bgColor}
            contentPadding={contentPadding}
            cardAlign={cardAlign}
          >
            <Image {...card.imageSrc} alt={'landing-card-image'} />
            <Description>
              <span>{card.description}</span>
              {card.remark && <Remark>{card.remark}</Remark>}
            </Description>
            <Title>{card.title}</Title>
          </CardBox>
        );
      case 'imageTitleDescription':
        return (
          <CardBox
            key={index}
            contentGap={contentGap}
            bgColor={bgColor}
            contentPadding={contentPadding}
            cardAlign={cardAlign}
          >
            <Image {...card.imageSrc} alt={'landing-card-image'} />
            <Title>{card.title}</Title>
            <Description>
              <span>{card.description}</span>
              {card.remark && <Remark>{card.remark}</Remark>}
            </Description>
          </CardBox>
        );
    }
  };

  return (
    <Container gridType={gridType}>
      {cards.map((card, i) => arrangeCards(contentOrder, card, i))}
    </Container>
  );
};

const Container = styled('div', {
  display: 'grid',
  gap: 16,
  justifyContent: 'center',
  marginTop: 120,
  marginBottom: 120,

  variants: {
    gridType: {
      twoRowsInFirstColumn: {
        '@landingTablet': {
          gridTemplateColumns: 'repeat(2, 1fr)',

          '& > div:nth-child(1)': {
            gridColumn: '1 / 2',
            gridRow: '1 / 2',
            order: 1,
          },

          '& > div:nth-child(2)': {
            gridColumn: '1 / 2',
            gridRow: '2 / 3',
            order: 3,
          },

          '& > div:nth-child(3)': {
            gridColumn: '2 / 3',
            gridRow: '1 / 3',
            order: 2,
          },
        },

        '@landingPC': {
          gridTemplateColumns: 'repeat(3, 1fr)',

          '& > div:nth-child(1)': {
            gridColumn: '1 / 2',
            gridRow: '1 / 2',
            order: 1,
          },

          '& > div:nth-child(2)': {
            gridColumn: '2 / 3',
            gridRow: '1 / 2',
            order: 2,
          },

          '& > div:nth-child(3)': {
            gridColumn: '3 / 4',
            gridRow: '1 / 2',
            order: 3,
          },
        },
      },

      twoRowsInSecondColumn: {
        '@landingTablet': {
          gridTemplateColumns: 'repeat(2, 1fr)',

          '& > div:nth-child(1)': {
            gridColumn: '1 / 2',
            gridRow: '1 / 3',
          },

          '& > div:nth-child(2)': {
            gridColumn: '2 / 3',
            gridRow: '1 / 2',
          },

          '& > div:nth-child(3)': {
            gridColumn: '2 / 3',
            gridRow: '2 / 3',
          },
        },

        '@landingPC': {
          gridTemplateColumns: 'repeat(3, 1fr)',

          '& > div:nth-child(1)': {
            gridColumn: '1 / 2',
            gridRow: '1 / 2',
          },

          '& > div:nth-child(2)': {
            gridColumn: '2 / 3',
            gridRow: '1 / 2',
          },

          '& > div:nth-child(3)': {
            gridColumn: '3 / 4',
            gridRow: '1 / 2',
          },
        },
      },
    },
  },
});

const CardBox = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: 24,
  paddingBottom: 64,
  borderRadius: 40,

  variants: {
    contentGap: {
      '8': {
        gap: 8,
      },
      '24': {
        gap: 24,
      },
    },

    bgColor: {
      purple: {
        backgroundColor: '$purple50',
      },
      mint: {
        backgroundColor: '$mint100',
      },
      teal: {
        backgroundColor: '$teal100',
      },
      yellow: {
        backgroundColor: '$yellow100',
      },
      blue: {
        backgroundColor: '$blue50',
      },
    },

    contentPadding: {
      '24': {
        padding: 24,
      },
      '64': {
        padding: 64,
        '@mobile': {
          /**
           * 모바일 지원을 위해 62로 설정.
           */
          padding: 62,
        },
      },
    },

    cardAlign: {
      flexStart: {
        alignItems: 'flex-start',
      },
      center: {
        alignItems: 'center',
      },
    },
  },
});

const Title = styled('h2', {
  fontType: 'heading3',
  whiteSpace: 'pre-line',
});

const Description = styled('p', {
  fontType: 'subtitleRegular',
  whiteSpace: 'pre-line',

  '& > span': {
    display: 'block',
    marginBottom: 8,
  },
});

const Remark = styled('span', {
  fontType: 'captionRegular',
  color: '$grey100',
});

export default CardList;
