import { css, styled } from '@styles/stitches.config';

const DoYouHaveTroubleBubbles = () => {
  return (
    <FlexBox>
      <Container>
        <Title>
          {'혹시'}
          <br />
          <span className={red100Css()}>{'이런 고민'}</span>
          {'하고 계신가요?'}
        </Title>
        <Bubbles>
          <BubbleContainer>
            <Bubble direction={'left'} color={'red'}>
              <BubbleText>
                <span className={boldCss()}>{'쇼핑몰 제작'}</span>
                {'도,'}
                <span className={boldCss()}>{'판매상품소싱'}</span>
                {'도'}
                <br />
                {'모르는데 어떻게 시작하죠? 🤷‍♀️'}
              </BubbleText>
            </Bubble>
          </BubbleContainer>
          <BubbleContainer direction={'right'}>
            <Bubble direction={'right'} color={'orange'}>
              <BubbleText>
                {'지금보다'} <span className={boldCss()}>{'더 높은 수익'}</span>
                {'을 내고 싶어요 💰'}
              </BubbleText>
            </Bubble>
          </BubbleContainer>
          <BubbleContainer>
            <Bubble direction={'left'} color={'red'}>
              <BubbleText>
                <span className={boldCss()}>{'배송부터 고객관리'}</span>
                {'까지,'}
                <br />
                {'혼자선 '}
                <span className={boldCss()}>{'시간'}</span>
                {'이 너무 부족해요 ⏰'}
              </BubbleText>
            </Bubble>
          </BubbleContainer>
        </Bubbles>
        <Title>
          {'이 모든 고민에 대한 해답이'}
          <br />
          <span className={purple200()}>{'케미'}</span>
          {'에 있어요!'}
        </Title>
      </Container>
    </FlexBox>
  );
};

const FlexBox = styled('div', {
  '@landingTablet': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const Container = styled('div', {
  padding: '128px 16px',

  '@landingTablet': {
    width: 400,
  },
});

const Title = styled('h2', {
  fontType: 'heading3',
  marginBottom: 72,
  color: '$black',
  whiteSpace: 'pre-line',
});

const Bubbles = styled('div', {
  marginBottom: 64,
});

const BubbleContainer = styled('div', {
  display: 'flex',

  variants: {
    direction: {
      right: {
        justifyContent: 'flex-end',
      },
    },
  },
});

const Bubble = styled('div', {
  padding: 24,
  marginTop: -8,

  variants: {
    direction: {
      left: {
        borderRadius: '42px 42px 42px 0px',
        boxShadow: '0px 16px 32px -8px #FF5064',
        justifyContent: 'flex-start',
      },
      right: {
        borderRadius: '42px 42px 0px 42px',
        boxShadow: '0px 16px 32px -8px #FFA064',
        justifyContent: 'flex-end',
      },
    },
    color: {
      red: {
        backgroundColor: '$red100',
      },
      orange: {
        backgroundColor: '$orange100',
      },
    },
  },
});

const BubbleText = styled('p', {
  color: '$white',
  fontType: 'bodyRegular',
  whiteSpace: 'pre-line',
});

const red100Css = css({
  color: '$red100',
});

const purple200 = css({
  color: '$purple200',
});

const boldCss = css({
  fontWeight: 700,
});

export default DoYouHaveTroubleBubbles;
